import React from 'react'

export default () => (
  <section id="education">
    <h2>Education</h2>
    <h3>University of North Texas</h3>
    <p>
      B.A., Information Technology
      <br />
      2007 &mdash; 2012
    </p>
  </section>
);
